import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "quickly-integrate-any-d3-code-in-your-react-project-with-blackbox-components"
    }}>{`Quickly integrate any D3 code in your React project with Blackbox Components`}</h1>
    <p><em parentName="p">{`This section teaches you the mental models of blackbox components and builds them up through class-based components. If you don't care about the details, you can jump ahead to using them with `}<a parentName="em" {...{
          "href": "/building-blocks/5"
        }}>{`React Hooks`}</a>{`.`}</em></p>
    <Vimeo id={424606537} mdxType="Vimeo" />
    <p>{`Blackbox components are the quickest way to integrate D3 and React. You can
think of them as wrappers around D3 visualizations.`}</p>
    <p>{`With the blackbox approach, you can take any D3 example from the internets or
your brain, wrap it in a React component, and it Just Works™. This is great
when you're in a hurry, but comes with a big caveat: You're letting D3 control
some of the DOM.`}</p>
    <p>{`D3 controlling the DOM is `}<em parentName="p">{`okay`}</em>{`, but it means React can't help you there.
That's why it's called a Blackbox – React can't see inside.`}</p>
    <p>{`No render engine, no tree diffing, no dev tools to inspect what's going. Just a
blob of DOM elements.`}</p>
    <p>{`Okay for small components or when you're prototyping, but I've had people come
to my workshops and say `}<em parentName="p">{`"We built our whole app with the blackbox approach. It
takes a few seconds to re-render when you click something. Please help"`}</em></p>
    <p>{`🤔`}</p>
    <p>{`Here's how it works:`}</p>
    <ul>
      <li parentName="ul">{`React renders an anchor element`}</li>
      <li parentName="ul">{`D3 hijacks it and puts stuff in`}</li>
    </ul>
    <p>{`You manually re-render on props and state changes. Throwing away and rebuilding
the entire DOM subtree on each render. With complex visualizations this becomes
a huge hit on performance.`}</p>
    <p>{`Use this technique sparingly.`}</p>
    <h2 {...{
      "id": "a-quick-blackbox-example---a-d3-axis"
    }}>{`A quick blackbox example - a D3 axis`}</h2>
    <Vimeo id={424606250} mdxType="Vimeo" />
    <p>{`Let's build an axis component. Axes are the perfect use-case for blackbox
components. D3 comes with an axis generator bundled inside, and they're
difficult to build from scratch.`}</p>
    <p>{`They don't `}<em parentName="p">{`look`}</em>{` difficult, but there are many tiny details you have to get
`}<em parentName="p">{`just right`}</em>{`.`}</p>
    <p>{`D3's axis generator takes a scale and some configuration to render an axis for
us. The code looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const scale = d3.scaleLinear().domain([0, 10]).range([0, 200])
const axis = d3.axisBottom(scale)

d3.select("svg").append("g").attr("transform", "translate(10, 30)").call(axis)
`}</code></pre>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/v6ovkow8q3"
      }}>{`try it out on CodeSandbox`}</a>{`.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/v6ovkow8q3",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`If this code doesn't make any sense, don't worry. There's a bunch of D3 to
learn, and I'll help you out. If it's obvious, you're a pro! This book will be
much quicker to read.`}</p>
    <p>{`We start with a linear scale that has a domain `}<inlineCode parentName="p">{`[0, 10]`}</inlineCode>{` and a range
`}<inlineCode parentName="p">{`[0, 200]`}</inlineCode>{`. Scales are like mathematical functions that map a domain to a
range. In this case, calling `}<inlineCode parentName="p">{`scale(0)`}</inlineCode>{` returns `}<inlineCode parentName="p">{`0`}</inlineCode>{`, `}<inlineCode parentName="p">{`scale(5)`}</inlineCode>{` returns `}<inlineCode parentName="p">{`100`}</inlineCode>{`,
`}<inlineCode parentName="p">{`scale(10)`}</inlineCode>{` returns `}<inlineCode parentName="p">{`200`}</inlineCode>{`. Just like a linear function from math class – y =
kx + n.`}</p>
    <p>{`We create an axis generator with `}<inlineCode parentName="p">{`axisBottom`}</inlineCode>{`, which takes a `}<inlineCode parentName="p">{`scale`}</inlineCode>{` and
creates a `}<inlineCode parentName="p">{`bottom`}</inlineCode>{` oriented axis – numbers below the line. You can also change
settings for the number of ticks, their sizing, spacing, and so on.`}</p>
    <p>{`Equipped with an `}<inlineCode parentName="p">{`axis`}</inlineCode>{` generator, we `}<inlineCode parentName="p">{`select`}</inlineCode>{` the `}<inlineCode parentName="p">{`svg`}</inlineCode>{` element, append a
grouping element, use a `}<inlineCode parentName="p">{`transform`}</inlineCode>{` attribute to move it `}<inlineCode parentName="p">{`10`}</inlineCode>{`px to the right
and `}<inlineCode parentName="p">{`30`}</inlineCode>{`px down, and invoke the generator with `}<inlineCode parentName="p">{`.call()`}</inlineCode>{`.`}</p>
    <p>{`It creates a small axis:`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/simple-axis.png",
        "alt": "Simple axis"
      }}></img></p>
    <p>{`Play around with it on `}<a parentName="p" {...{
        "href": "https://codepen.io/swizec/pen/YGoYBM"
      }}>{`Codesandbox`}</a>{`.
Change the scale type, play with axis orientation. Use `}<inlineCode parentName="p">{`.ticks`}</inlineCode>{` on the axis to
change how many show up. Have some fun 😃`}</p>
    <h2 {...{
      "id": "a-quick-blackbox-example---a-reactd3-axis"
    }}>{`A quick blackbox example - a React+D3 axis`}</h2>
    <Vimeo id={424605514} mdxType="Vimeo" />
    <p>{`Now let's say we want to use that same axis code but as a React component. The
simplest way is to use a blackbox component approach like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Axis extends Component {
  gRef = React.createRef()

  componentDidMount() {
    this.d3render()
  }
  componentDidUpdate() {
    this.d3render()
  }

  d3render() {
    const scale = d3.scaleLinear().domain([0, 10]).range([0, 200])
    const axis = d3.axisBottom(scale)

    d3.select(this.gRef).call(axis)
  }

  render() {
    return <g transform="translate(10, 30)" ref={this.gRef} />
  }
}
`}</code></pre>
    <p>{`So much code! Worth it for the other benefits of using React in your dataviz.
You'll see 😃`}</p>
    <p>{`We created an `}<inlineCode parentName="p">{`Axis`}</inlineCode>{` component that extends React's base `}<inlineCode parentName="p">{`Component`}</inlineCode>{` class. We
can't use functional components because we need lifecycle hooks.`}</p>
    <p>{`Our component has a `}<inlineCode parentName="p">{`render`}</inlineCode>{` method. It returns a grouping element (`}<inlineCode parentName="p">{`g`}</inlineCode>{`) moved
10px to the right and 30px down using the `}<inlineCode parentName="p">{`transform`}</inlineCode>{` attribute. Same as
before.`}</p>
    <p>{`A React ref saved in `}<inlineCode parentName="p">{`this.gRef`}</inlineCode>{` and passed into our `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element with `}<inlineCode parentName="p">{`ref`}</inlineCode>{`
lets us talk to the DOM node directly. We need this to hand over rendering
control to D3.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`d3render`}</inlineCode>{` method looks familiar. It's the same code we used in the vanilla
D3 example. Scale, axis, select, call. Only difference is that instead of
selecting `}<inlineCode parentName="p">{`svg`}</inlineCode>{` and appending a `}<inlineCode parentName="p">{`g`}</inlineCode>{` element, we select the `}<inlineCode parentName="p">{`g`}</inlineCode>{` element rendered
by React and use that.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` and `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` to keep our render up to
date. Ensures that our axis re-renders every time React's engine decides to
render our component.`}</p>
    <p>{`That wasn't so bad, was it?`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/3xy2jr1y5m"
      }}>{`Try it out on Codesandbox`}</a>{`.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/3xy2jr1y5m",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`You can make the axis more useful by getting positioning, scale, and
orientation from props. We'll do that in our big project.`}</p>
    <h3 {...{
      "id": "practical-exercise"
    }}>{`Practical exercise`}</h3>
    <p>{`Try implementing those as an exercise. Make the axis more reusable with some
carefully placed props.`}</p>
    <p>{`Here's my solution, if you get stuck 👉
`}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/5ywlj6jn4l"
      }}>{`https://codesandbox.io/s/5ywlj6jn4l`}</a></p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/5ywlj6jn4l",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <Vimeo id={424606063} mdxType="Vimeo" />
    <h2 {...{
      "id": "a-d3-blackbox-higher-order-component--hoc"
    }}>{`A D3 blackbox higher order component – HOC`}</h2>
    <Vimeo id={424605202} mdxType="Vimeo" />
    <p>{`After that example you might think this is hella tedious to implement every
time. You'd be right!`}</p>
    <p>{`Good thing you can abstract it all away with a higher order component – a HOC.
Now this is something I should open source (just do it already), but I want to
show you how it works so you can learn about the HOC pattern.`}</p>
    <p>{`Higher order components are great when you see multiple React components
sharing similar code. In our case, that shared code is:`}</p>
    <ul>
      <li parentName="ul">{`rendering an anchor element`}</li>
      <li parentName="ul">{`calling D3's render on updates`}</li>
    </ul>
    <p>{`With a HOC, we can abstract that away into a sort of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Factory_method_pattern"
      }}>{`object factory`}</a>{`. It's an old concept making a comeback now that JavaScript has classes.`}</p>
    <p>{`Think of our HOC as a function that takes some params and creates a class – a
React component. Another way to think about HOCs is that they're React
components wrapping other React components and a function that makes it easy.`}</p>
    <p>{`A HOC for D3 blackbox integration, called `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{`, looks like like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function D3blackbox(D3render) {
  return class Blackbox extends React.Component {
    anchor = React.createRef()

    componentDidMount() {
      D3render.call(this)
    }
    componentDidUpdate() {
      D3render.call(this)
    }

    render() {
      const { x, y } = this.props
      return <g transform={\`translate(\${x}, \${y})\`} ref={this.anchor} />
    }
  }
}
`}</code></pre>
    <p>{`You'll recognize most of that code from earlier.`}</p>
    <p>{`We have `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and`}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` lifecycle hooks that call
`}<inlineCode parentName="p">{`D3render`}</inlineCode>{` on component updates. `}<inlineCode parentName="p">{`render`}</inlineCode>{` renders a grouping element as an
anchor with a ref so D3 can use it to render stuff into.`}</p>
    <p>{`Because `}<inlineCode parentName="p">{`D3render`}</inlineCode>{` is no longer a part of our component, we have to use `}<inlineCode parentName="p">{`.call`}</inlineCode>{`
to give it the scope we want: this class, or rather `}<inlineCode parentName="p">{`this`}</inlineCode>{` instance of the
`}<inlineCode parentName="p">{`Blackbox`}</inlineCode>{` class.`}</p>
    <p>{`We've also made some changes that make `}<inlineCode parentName="p">{`render`}</inlineCode>{` more flexible. Instead of
hardcoding the `}<inlineCode parentName="p">{`translate()`}</inlineCode>{` transformation, we take `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` props.
`}<inlineCode parentName="p">{`{ x, y } = this.props`}</inlineCode>{` takes `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` out of `}<inlineCode parentName="p">{`this.props`}</inlineCode>{` using object
decomposition, and we used ES6 string templates for the `}<inlineCode parentName="p">{`transform`}</inlineCode>{` attribute.`}</p>
    <p>{`Consult the `}<a parentName="p" {...{
        "href": "https://es6cheatsheet.com/"
      }}>{`ES6 cheatsheet`}</a>{` for details on the
syntax.`}</p>
    <p>{`Using our new `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{` HOC to make an axis looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Axis = D3blackbox(function () {
  const scale = d3.scaleLinear().domain([0, 10]).range([0, 200])
  const axis = d3.axisBottom(scale)

  d3.select(this.anchor).call(axis)
})
`}</code></pre>
    <p>{`You know this code! We copy pasted our axis rendering code from before, wrapped
it in a function, and passed it into `}<inlineCode parentName="p">{`D3blackbox`}</inlineCode>{`. Now it's a React component.`}</p>
    <p>{`Play with this example on
`}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/5v21r0wo4x"
      }}>{`Codesandbox, here`}</a>{`.`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/5v21r0wo4x",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      